<template>
    <FinalField
        :name="name"
        :validate="validate"
    >
        <template v-slot="props">
            <div class="form-field">
                <label
                    v-if="label"
                    class="label"
                >{{ label }}</label
                >
                <label class="switch-label">
                    <input
                        v-model="props.value"
                        type="checkbox"
                        class="switch"
                        :data-test="name"
                        :disabled="disabled"
                        @blur="props.events.blur()"
                        @focus="props.events.focus()"
                        @change="props.change(props.value)"
                    />
                    {{ value }}
                </label>

                <FieldError :name="name" />
            </div>
        </template>
    </FinalField>
</template>

<script>
import { FinalField } from 'vue-final-form';
import FieldError from '@/components/form/FieldError';

export default {
    components: {
        FinalField,
        FieldError,
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        label: {
            type: String,
        },

        validate: {
            type: [Function, Array],
            required: false,
        },

        value: {
            type: String,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
