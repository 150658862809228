<template>
    <FinalField
        :name="name"
        :validate="validate"
    >
        <template v-slot="props">
            <div :class="['form-field', $attrs['class']]">
                <label
                    v-if="label"
                    class="label"
                >{{ label }}</label
                >

                <div v-if="!editMode">{{ textValue(props.value) }}</div>

                <DateInput
                    v-if="editMode"
                    :class="{ error: props.meta.error && props.meta.touched }"
                    :name="name"
                    :modelValue="props.value"
                    :format="format"
                    v-bind="inputAttrs"
                    @update:modelValue="props.change(formatter(prepareDateInTimezone($event)))"
                    @clear="props.change(null)"
                    @updateMonthYear="$emit('updateMonthYear', $event)"
                    @open="$emit('open')"
                    @close="$emit('close')"
                />

                <div
                    v-if="helperText"
                    class="field-hint"
                >
                    {{ helperText }}
                </div>

                <FieldError
                    v-if="!suppressError"
                    :name="name"
                />
            </div>
        </template>
    </FinalField>
</template>

<script>
import { FinalField } from 'vue-final-form';
import { omit } from 'lodash-es';
import DateInput from '@/components/ui/DateInput';
import CommunityTimezoneMixin from '@/mixins/CommunityTimezoneMixin';
import FieldError from '@/components/form/FieldError';

export default {
    components: {
        FinalField,
        DateInput,
        FieldError,
    },

    mixins: [CommunityTimezoneMixin],

    inheritAttrs: false,

    props: {
        name: {
            type: String,
            required: true,
        },

        label: {
            type: String,
        },

        helperText: {
            type: String,
        },

        format: {
            type: String,
            default: 'MM/dd/yyyy',
        },

        validate: {
            type: [Function, Array],
            required: false,
        },

        editMode: {
            type: Boolean,
            default: true,
        },

        /**
         * Formats value before saving to the form state
         */
        formatter: {
            type: Function,
            default: value => value,
        },

        suppressError: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['updateMonthYear', 'open', 'close'],

    computed: {
        inputAttrs() {
            return omit(this.$attrs, 'class');
        },
    },

    methods: {
        prepareDateInTimezone(date) {
            if (!date) {
                return null;
            }

            return this.parseDateTime(date).toDate();
        },

        textValue(value) {
            if (!value) {
                return '-';
            }

            const m = this.parseDateTime(value);

            if (!m.isValid()) {
                return '-';
            }

            return m.format(this.format);
        },

        customFormatter(date) {
            return this.parseDateTime(date).format(this.format);
        },
    },
};
</script>
