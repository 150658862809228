<template>
    <FinalField
        ref="field"
        :name="name"
        :validate="validate"
    >
        <template v-slot="props">
            <div class="form-field">
                <div
                    v-if="label"
                    class="label"
                >
                    {{ label }}
                </div>

                <AttachmentsPreviews
                    :class="{ 'inline-preview': !inline }"
                    :items="props.value"
                    :file-dialog-options="fileDialogOptions"
                    :editable="!disabled"
                    @update:items="props.change"
                    @addNewClick="handleAddNewClick"
                />

                <FieldError :name="name" />

                <div
                    v-if="!inline && expanded"
                    class="expanded-overlay"
                >
                    <div class="flex flex-col w-full h-full">
                        <div class="flex items-center px-6 pt-4">
                            <icon
                                name="arrow-left"
                                class="md:hidden flex-shrink-0 h-6 w-6 mr-3 text-gray-800 cursor-pointer"
                                @click="closeExpandedView"
                            />
                            <div class="page-title flex-grow">{{ $t('attachments.attachments') }}</div>
                            <icon
                                name="close"
                                class="z-50 h-6 w-6 cursor-pointer"
                                @click="closeExpandedView"
                            />
                        </div>

                        <div class="flex-grow p-6 overflow-auto">
                            <div
                                v-if="props.meta.error"
                                class="field-error mb-4"
                            >
                                {{ props.meta.error }}
                            </div>

                            <div
                                v-if="!disabled"
                                :class="newItems.length === 0 ? 'mb-8' : ''"
                            >
                                <div class="label">
                                    {{ $t('attachments.field.new_files_count', { num: newItems.length }) }}
                                </div>
                                <AttachmentsPreviews
                                    :items="newItems"
                                    :file-dialog-options="fileDialogOptions"
                                    editable
                                    @update:items="handleNewItemsChange"
                                />

                                <button
                                    v-if="newItems.length > 0"
                                    type="button"
                                    class="btn border-none w-full mt-2.5 mb-4"
                                    @click="clearAllNew"
                                >
                                    {{ $t('attachments.field.clear_all_new') }}
                                </button>
                            </div>

                            <template v-if="oldItems.length > 1">
                                <div class="label">
                                    {{ $t('attachments.field.old_files_count', { num: oldItems.length }) }}
                                </div>
                                <AttachmentsPreviews
                                    :items="oldItems"
                                    :file-dialog-options="fileDialogOptions"
                                />
                            </template>
                        </div>

                        <div class="flex p-6">
                            <button
                                type="button"
                                class="btn w-1/2"
                                @click="closeExpandedView"
                            >
                                {{ $t('app.cancel_action') }}
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary w-1/2"
                                :disabled="newItems.length === 0 || props.meta.error"
                                @click="handleSaveClick"
                            >
                                {{ $t('app.save_action') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </FinalField>
</template>

<script>
import { FinalField } from 'vue-final-form';
import AttachmentsPreviews from '@/components/ui/atts/AttachmentsPreviews';
import Icon from '@/components/ui/Icon';
import FieldError from '@/components/form/FieldError';

export default {
    name: 'AttachmentsField',

    components: { Icon, AttachmentsPreviews, FinalField, FieldError },

    props: {
        name: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: false,
        },

        validate: {
            type: [Function, Array],
            required: false,
        },

        fileDialogOptions: {
            type: Object,
            default: () => ({}),
        },

        inline: {
            type: Boolean,
            default: false,
        },

        expanded: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['update:expanded', 'save'],

    computed: {
        newItems() {
            return this.$refs.field.fieldState.value.filter(({ attached }) => !attached);
        },

        oldItems() {
            return this.$refs.field.fieldState.value.filter(({ attached }) => attached);
        },
    },

    mounted() {},

    methods: {
        handleAddNewClick() {
            if (!this.inline) {
                this.$emit('update:expanded', true);
            }
        },

        handleNewItemsChange(items) {
            this.$refs.field.fieldState.change([...items, ...this.oldItems]);
        },

        clearAllNew() {
            this.$refs.field.fieldState.change(this.oldItems);
        },

        closeExpandedView() {
            this.$refs.field.fieldState.change(this.oldItems);
            this.$emit('update:expanded', false);
        },

        handleSaveClick() {
            this.$emit('save', this.$refs.field.fieldState.value);
        },
    },
};
</script>

<style scoped>
.inline-preview :deep(.attachment-tile:nth-child(n + 8)) {
    @apply hidden;
}

.expanded-overlay {
    @apply fixed z-50 top-0 bottom-0 left-0 w-full bg-white;
}

.btn-primary:disabled {
    @apply bg-gray-400 border-gray-400;
}
</style>
