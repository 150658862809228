export const STATUS_COMPLETED = 'COMPLETED';
export const STATUS_CANCELED = 'CANCELED';
export const STATUS_RESIDENT_VALIDATION = 'RESIDENT_VALIDATION';

export const STATUS_GROUP_OPENED = 'OPENED';
export const STATUS_GROUP_RESOLVED = 'RESOLVED';
export const STATUS_GROUP_RESIDENT_VALIDATION = 'RESIDENT_VALIDATION';

export const LOCATION_TYPE_BUILDING = 'BUILDING';
export const LOCATION_TYPE_UNIT = 'UNIT';
export const LOCATION_TYPE_APARTMENT = 'APARTMENT';
export const LOCATION_TYPE_CUSTOM = 'CUSTOM';

export const CHANNEL_EMAIL = 'EMAIL';
export const CHANNEL_PHONE = 'PHONE';

export const SUBMITTER_RESIDENT = 'SUBMITTER_RESIDENT';
export const SUBMITTER_MANAGER = 'SUBMITTER_MANAGER';

export const AREA_UNIT = 'AREA_UNIT';
export const AREA_COMMON = 'AREA_COMMON';

export const LOCATION_CUSTOM = 'LOCATION_CUSTOM';

export const MODIFICATION_RULE_READ_ONLY = 'READ_ONLY';

export const MESSAGE_TYPE_SIMPLE = 'SIMPLE';

export const NOTE_TYPE_ORDINARY = 'ORDINARY';
export const NOTE_TYPE_COST_ALLOCATION = 'COST_ALLOCATION';

export const USER_TYPE_QUEXT = 'QUEXT';
export const USER_TYPE_RESIDENT = 'RESIDENT';

export const MESSAGE_STATUS_PENDING = 'PENDING';
export const MESSAGE_STATUS_FAILED = 'FAILED';
export const MESSAGE_STATUS_RECEIVED = 'RECEIVED';

export const CHAT_ACTION_READ = 'READ';
export const CHAT_ACTION_WRITE = 'WRITE';

export const CATEGORY_CUSTOM = 'CATEGORY_CUSTOM';

export const SCHEMA_STATUS = {
    BASIC: 'BASIC',
    ADVANCED: 'ADVANCED',
};
