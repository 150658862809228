<template>
    <FinalField
        :name="name"
        :validate="validate"
        type="radio"
    >
        <template v-slot="props">
            <div class="form-field">
                <label class="label mb-0">{{ label }}</label>
                <div>
                    <label
                        v-for="option in options"
                        :key="option.key"
                        class="radio-label"
                    >
                        <input
                            type="radio"
                            class="radio"
                            :name="props.name"
                            :checked="props.value === option.key"
                            :value="option.key"
                            v-on="props.events"
                        />
                        {{ option.value }}
                    </label>
                </div>

                <FieldError :name="name" />
            </div>
        </template>
    </FinalField>
</template>

<script>
import { FinalField } from 'vue-final-form';
import FieldError from '@/components/form/FieldError';

export default {
    components: {
        FinalField,
        FieldError,
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: true,
        },

        options: {
            type: Array,
            required: true,
        },

        validate: {
            type: [Function, Array],
            required: false,
        },
    },
};
</script>
