<template>
    <label class="checkbox">
        <input
            v-model="checked"
            type="checkbox"
            class="checkbox__input"
            :disabled="disabled"
            @blur="$emit('blur')"
            @focus="$emit('focus')"
            @change="() => $emit('update:modelValue', checked)"
        />

        <Icon
            v-if="checked"
            name="checkmark"
            class="checkbox__icon"
        />

        <span
            v-if="label"
            class="checkbox__label"
        >
            {{ label }}
        </span>
    </label>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    components: { Icon },

    props: {
        /**
         * Checkbox value
         */
        modelValue: {
            type: Boolean,
            required: true,
        },

        /**
         * Input label
         */
        label: {
            type: String,
        },

        /**
         * Set disabled state
         */
        disabled: {
            type: Boolean,
        },
    },

    emits: [
        /**
         * Emmited on losing focus
         */
        'blur',
        /**
         * Emitted on focus
         */
        'focus',
        /**
         * Emitted on change
         */
        'update:modelValue',
    ],

    data: () => ({
        checked: false,
    }),

    watch: {
        modelValue: {
            handler(val) {
                this.checked = val;
            },

            immediate: true,
        },
    },
};
</script>

<style scoped>
.checkbox {
    @apply inline-flex items-center relative font-ffdin font-medium text-sm text-gray-800 uppercase tracking-widest cursor-pointer;
}

.checkbox__label {
    @apply ml-2 mt-1;
}

.checkbox__input {
    @apply appearance-none outline-none cursor-pointer border rounded-sm border-gray-500 relative;
    width: 0.8125rem;
    height: 0.8125rem;
}

.checkbox__input:checked {
    @apply bg-purple-600 border-purple-600;
}

.checkbox__icon {
    @apply absolute text-white;
    width: 0.8125rem;
    height: 0.8125rem;
}
</style>
