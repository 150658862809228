<script>
import { useI18n } from 'vue-i18n';
import { AREA_COMMON, AREA_UNIT, LOCATION_CUSTOM } from './constants';

export default {
    name: 'LocationMixin',

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            locations: {
                root: [],
            },
        };
    },

    methods: {
        loadLocationsData(areaType, parentId, residentId) {
            const shared = areaType === AREA_COMMON;

            return this.$sreqDataProvider.getList('locations', { shared, parentId, residentId }).then(locations => {
                this.locations = this.collectLocations(locations);
            });
        },

        collectLocations(locations, acc = {}, parentId = 'root') {
            acc[parentId] = acc[parentId] ?? [];

            if (locations && locations.length) {
                acc[parentId] = locations.map(this.mapItemToOption);
                locations.forEach(location => {
                    this.collectLocations(location.locations, acc, location.id);
                });
            }

            return acc;
        },

        compareLocationPaths(locationPath, oldLocationPath, loadLocations, updateLocationPath) {
            if (!locationPath || !Array.isArray(locationPath)) {
                return;
            }

            const index = oldLocationPath.length
                ? locationPath.findIndex((item, i) => item.key !== oldLocationPath[i].key)
                : locationPath.length;

            if (index < 0) {
                return;
            }

            const updatedLocationPath = locationPath.slice(0, index + 1);

            const lastId = updatedLocationPath.slice(-1)[0];

            if (lastId && lastId !== LOCATION_CUSTOM && (!this.locations[lastId] || !this.locations[lastId]?.length)) {
                loadLocations(lastId);
            }

            if (index >= locationPath.length - 1) {
                return;
            }

            updateLocationPath(updatedLocationPath);
        },

        locationDropdownOptions(locationPath, areaType, index) {
            if (index === 0) {
                return this.locations.root;
            }

            if (!locationPath) {
                return [];
            }

            const parentId = locationPath[index - 1];

            const childrenLocations = this.locations[parentId];

            if (!childrenLocations || childrenLocations.length === 0) {
                return [];
            }

            if ((areaType === AREA_UNIT && index >= 2) || (areaType === AREA_COMMON && index >= 1)) {
                return [
                    ...childrenLocations,
                    {
                        key: LOCATION_CUSTOM,
                        value: this.t('sreq.location.other'),
                    },
                ];
            }

            return childrenLocations;
        },

        locationValidation(areaType, index) {
            if ((areaType === AREA_UNIT && index <= 1) || (areaType === AREA_COMMON && index === 0)) {
                return this.required;
            }

            return null;
        },

        isCustomLocation(locationPath = []) {
            return locationPath.filter(loc => loc?.key).slice(-1)[0]?.key === LOCATION_CUSTOM;
        },

        getLastPredefinedLocation(locationPath = []) {
            return locationPath.filter(loc => loc?.key && loc.key !== LOCATION_CUSTOM).slice(-1)[0];
        },
    },
};
</script>
