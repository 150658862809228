<template>
    <Form
        ref="form"
        class="h-full"
        :initial-values="initialValues"
        :submit="handleSubmit"
    >
        <template v-slot="props">
            <Page
                ref="page"
                :title="$t('sreq.create.title')"
                :props="props"
                :loading="loading"
                :custom-back-button="false"
                :show-back-button="false"
            >
                <template v-slot:default="{ props }">
                    <RadioGroupField
                        name="areaType"
                        :label="$t('sreq.location.title')"
                        :options="areaTypes"
                    />
                    <SelectField
                        v-show="props.values.areaType !== AREA_UNIT"
                        trackBy="key"
                        :name="`locationPath.${0}`"
                        label="location"
                        object-mode
                        :options="locationDropdownOptions(props.values.locationPath, props.values.areaType, 0)"
                        :validate="locationValidation(props.values.areaType, index)"
                    />

                    <div
                        v-if="props.values.areaType === AREA_UNIT"
                        class="mt-8 mb-8"
                    >
                        <label class="label">
                            {{ $t('sreq.enter_permission.description') }}
                        </label>
                        <SwitchField
                            class="mt-6 mb-4"
                            name="enterPermissionGranted"
                            :value="$t('app.yes')"
                        />
                        <div
                            v-if="props.values.enterPermissionGranted"
                            class="infobox flex bg-red-200 border-red-300"
                        >
                            <icon
                                name="exclamation-circle"
                                class="text-red-600 w-3.5 h-3.5 mr-4"
                            />
                            {{ $t('sreq.enter_permission.notice') }}
                        </div>
                        <TextField
                            v-if="alarmCodeStatus"
                            class="mt-6"
                            :label="$t('sreq.fields.alarm_code')"
                            name="alarmCode"
                            :validate="maxLength(50)"
                        />
                    </div>

                    <DateField
                        name="notice"
                        label="when did you notice the problem?*"
                        :validate="required"
                    />

                    <hr class="my-8" />

                    <template
                        v-for="(problem, index) in props.values.problems"
                        :key="index"
                    >
                        <SelectField
                            :name="`problems[${index}].categoryPath.${0}`"
                            label="select the category of your problem*"
                            track-by="key"
                            :options="categoryDropdownOptions(problem.categoryPath, 0)"
                            :validate="required"
                        />

                        <SelectField
                            v-show="props.values.areaType === AREA_UNIT"
                            :name="`problems[${index}].appliance`"
                            label="select the affected appliance"
                            track-by="key"
                            :options="appliances"
                        />

                        <TextField
                            :name="`problems[${index}].description`"
                            :label="$t('sreq.fields.description') + '*'"
                            multiline
                            rows="5"
                            :validate="composeValidators(required, minLength(10), maxLength(1000))"
                        />
                        <AttachmentsField
                            inline
                            :name="`problems[${index}].attachments`"
                            :label="$t('sreq.fields.attachments')"
                            :validate="composeValidators(maxAttachmentsNumber(20))"
                            :file-dialog-options="fileDialogOptions"
                        />

                        <div class="flex justify-end mt-4">
                            <button
                                v-if="index === props.values.problems.length - 1"
                                type="button"
                                class="btn-link flex items-center"
                                @click="handleProblemAdd"
                            >
                                <div class="problem-icon-wrapper">
                                    <Icon
                                        name="plus"
                                        class="w-2 h-2 text-white"
                                    />
                                </div>
                                <span>Add new problem in the same location</span>
                            </button>
                            <button
                                v-else
                                type="button"
                                class="btn-link flex items-center"
                                @click="() => handleProblemDelete(props.values.problems, index)"
                            >
                                <div class="problem-icon-wrapper problem-icon-wrapper--red">
                                    <Icon
                                        name="close"
                                        class="w-2 h-2 text-white"
                                    />
                                </div>
                                <span class="text-red-900">Delete problem</span>
                            </button>
                        </div>

                        <hr class="my-8" />
                    </template>

                    <div class="mt-2 mb-12">
                        <label class="label">{{ $t('sreq.notifications.preferred_title') }}</label>
                        <RadioField
                            name="contactPreferences.preferredChannel"
                            :label="$t('sreq.notifications.email')"
                            value="EMAIL"
                        />
                        <TextField
                            class="mt-3"
                            name="contactPreferences.email"
                            label=""
                            naked
                            :disabled="props.values.contactPreferences.preferredChannel !== 'EMAIL'"
                            :validate="triggerValidator('contactPreferences.preferredChannel', 'EMAIL', composeValidators(required, email))"
                        />

                        <RadioField
                            name="contactPreferences.preferredChannel"
                            :label="$t('sreq.notifications.text')"
                            value="PHONE"
                        />
                        <PhoneField
                            class="mt-3"
                            name="contactPreferences.phone"
                            label=""
                            naked
                            :disabled="props.values.contactPreferences.preferredChannel !== 'PHONE'"
                            :validate="
                                triggerValidator('contactPreferences.preferredChannel', 'PHONE', composeValidators(required, phoneNumber))
                            "
                        />
                    </div>

                    <CheckboxGroupField
                        class="mt-12"
                        :label="$t('sreq.notifications.events_title')"
                        :options="[
                            {
                                name: 'notificationPreferences.sendOnStatusChange',
                                label: $t('sreq.notifications.status_change'),
                            },
                            {
                                name: 'notificationPreferences.sendOnNewChatMessage',
                                label: $t('sreq.notifications.new_chat_message'),
                            },
                        ]"
                    />
                </template>

                <template v-slot:footer>
                    <div class="flex px-6 pt-6">
                        <button
                            type="button"
                            class="flex-1 btn"
                            @click="handleSubmitAndAddNew(props.values, true)"
                        >
                            submit and add new
                        </button>
                        <button
                            type="submit"
                            class="flex-1 btn btn-primary"
                        >
                            {{ $t('sreq.create.submit_action') }}
                        </button>
                    </div>
                    <div class="flex px-6 py-6">
                        <button
                            type="button"
                            class="flex-1 btn btn-transparent text-black"
                            @click="close"
                        >
                            {{ $t('app.cancel_action') }}
                        </button>
                    </div>
                </template>
            </Page>
        </template>
    </Form>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { sortBy } from 'lodash-es';
import Page from '@/components/ui/Page';
import Icon from '@/components/ui/Icon';
import Form from '@/components/form/Form';
import TextField from '@/components/form/TextField';
import DateField from '@/components/form/DateField';
import SelectField from '@/components/form/SelectField';
import CheckboxGroupField from '@/components/form/CheckboxGroupField';
import RadioGroupField from '@/components/form/RadioGroupField';
import RadioField from '@/components/form/RadioField';
import SwitchField from '@/components/form/SwitchField';
import PhoneField from '@/components/form/PhoneField';
import ValidatorMixin from '@/mixins/ValidatorMixin';
import ConfirmationMixin from '@/mixins/ConfirmationMixin';
import LocationMixin from './LocationMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import { AREA_COMMON, AREA_UNIT } from './constants';
import AttachmentsField from '@/components/form/AttachmentsField';
import AttachmentsUploadMixin from '@/mixins/AttachmentsUploadMixin';
import { fileDialogOptionsDefaults } from '@/utils/files';

const NEW_PROBLEM = {
    categoryPath: [''],
    description: '',
    attachments: [],
};

export default {
    components: {
        AttachmentsField,
        TextField,
        DateField,
        SelectField,
        CheckboxGroupField,
        RadioGroupField,
        RadioField,
        SwitchField,
        PhoneField,
        Form,
        Icon,
        Page,
    },

    mixins: [AttachmentsUploadMixin, ValidatorMixin, ConfirmationMixin, LocationMixin, NotifyMixin],

    async beforeRouteLeave(to, from, next) {
        const { pristine } = this.$refs.form.finalForm.getState();

        const result =
            this.submitted ||
            pristine ||
            (await this.requestConfirmation({
                title: this.t('sreq.confirmation.leave.text'),
                cancelCaption: this.t('sreq.confirmation.leave.cancel_caption'),
            }));

        next(result);
    },

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data() {
        return {
            fileDialogOptions: fileDialogOptionsDefaults.SREQ,
            initialValues: {
                locationPath: [],
                areaType: AREA_UNIT,
                notice: null,
                contactPreferences: {
                    email: '',
                    phone: '',
                    preferredChannel: 'EMAIL',
                },

                notificationPreferences: {
                    sendOnStatusChange: true,
                    sendOnNewChatMessage: true,
                },

                enterPermissionGranted: false,

                problems: [NEW_PROBLEM],
            },

            profile: null,
            loading: false,
            submitted: false,
            categories: [],
            appliances: [],
            areaTypes: [
                { key: AREA_UNIT, value: this.t('sreq.location.in_my_unit') },
                { key: AREA_COMMON, value: this.t('sreq.location.somewhere_else') },
            ],

            AREA_UNIT,
            // overrides value in attachments upload mixin
            serviceId: 'SREQ',

            alarmCodeStatus: false,
        };
    },

    computed: {
        formValues() {
            return this.$refs.form.formState.values;
        },
    },

    mounted() {
        const { form } = this.$refs;

        this.loadReferences();

        this.$watch(
            () => this.formValues.areaType,
            areaType => {
                if (areaType === AREA_COMMON) {
                    form.finalForm.change('enterPermissionGranted', false);
                    form.finalForm.change('alarmCode', null);
                    form.finalForm.change('locationPath', []);
                }

                this.initLocationPath();
            }
        );
    },

    methods: {
        loadCategoriesData(parentId) {
            return this.$sreqDataProvider.getList('categories', { parentId }).then(categories => {
                this.categories = this.collectCategories(categories, { ...this.categories }, parentId);
            });
        },

        async loadAppliancesData(unitId) {
            await this.$sreqDataProvider.get('appliances', { unitId: unitId }).then(appliances => {
                this.appliances = appliances.map(appliance => {
                    return {
                        key: appliance.id,
                        value: appliance.name,
                    };
                });
            });
        },

        collectCategories(categories, acc = {}, parentId = 'root') {
            acc[parentId] = acc[parentId] ?? [];

            if (categories && categories.length) {
                acc[parentId] = categories.map(this.mapItemToOption);
            }

            return acc;
        },

        categoryDropdownOptions(categoryPath, index) {
            if (!categoryPath) {
                return [];
            }

            const parentId = index === 0 ? 'root' : categoryPath[index - 1];

            const childrenCategories = sortBy(this.categories[parentId], 'value');

            if (!childrenCategories || childrenCategories.length === 0) {
                return [];
            }

            return [...childrenCategories];
        },

        categoryValidation(index, validator) {
            if (index === 0) {
                return validator;
            }

            return null;
        },

        loadReferences() {
            this.$sreqDataProvider.get('profile').then(profile => {
                this.profile = profile;

                const profileInitialValues = {
                    residentEmail: profile.email,
                    residentPhone: profile.phone,
                    contactPreferences: profile.defaultContactPreferences,
                    notificationPreferences: profile.defaultNotificationPreferences,
                };

                this.initialValues = {
                    ...this.initialValues,
                    ...profileInitialValues,
                };
            });

            this.getCommunitySettings();

            this.loadCategoriesData();

            this.initLocationPath();
        },

        getCommunitySettings() {
            this.$sreqDataProvider
                .getOne('communitySettings')
                .then(({ alarmCode }) => {
                    this.alarmCodeStatus = alarmCode;
                })
                .catch(error => {
                    this.notifyError(error.message);
                });
        },

        async initLocationPath() {
            await this.loadLocations();

            if (this.formValues.areaType === AREA_UNIT) {
                const unit = this.locations.root?.[0];
                const unitId = unit?.key;
                const unit2 = this.locations[unitId]?.[0];

                if (unitId) {
                    await this.loadAppliancesData(unitId);
                }

                const { form } = this.$refs;
                const formLocationPath = [unit];

                if (unit2) {
                    formLocationPath.push(unit2);
                }

                form.finalForm.change('locationPath', formLocationPath);
            }

            const locationPath = [];

            // eslint-disable-next-line no-constant-condition
            while (true) {
                const options = this.locationDropdownOptions(locationPath, this.formValues.areaType, locationPath.length);

                if (options.length === 1) {
                    locationPath.push(options[0]);
                } else {
                    break;
                }
            }

            this.initialValues.locationPath = [];

            this.$nextTick(() => {
                this.initialValues.locationPath = locationPath;
            });
        },

        loadLocations(parentId) {
            const { areaType, resident } = this.formValues;

            this.loading = true;
            return this.loadLocationsData(areaType, parentId, resident)
                .catch(error => this.notifyError(error.message))
                .finally(() => {
                    this.loading = false;
                });
        },

        mapItemToOption(item) {
            return {
                key: item.id,
                value: item.name.toLowerCase(),
                type: item.type,
            };
        },

        otherLocationLabel(areaType) {
            if (areaType === AREA_UNIT) {
                return this.t('sreq.location.other_location') + '*';
            }

            return this.t('sreq.location.other_level') + '*';
        },

        handleProblemAdd() {
            this.$refs.form.finalForm.change('problems', [
                ...this.formValues.problems,
                {
                    ...NEW_PROBLEM,
                },
            ]);
        },

        handleProblemDelete(problems, index) {
            this.$refs.form.finalForm.change(
                'problems',
                problems.filter((__, _index) => index !== _index)
            );
        },

        async handleSubmit(values) {
            if (this.loading) {
                return;
            }

            return this.submitRequestData(values);
        },

        async handleSubmitAndAddNew(values) {
            if (this.loading) {
                return;
            }

            return this.submitRequestData(values, true);
        },

        async submitRequestData(values, addNew = false) {
            this.loading = true;

            try {
                this.attachments = values.problems.map(({ attachments }) => attachments).flat();
                await this.uploadAttachments();

                await this.$sreqDataProvider.create('serviceRequests', {
                    data: this.getRequestPayload({ ...values, attachments: this.attachments }),
                });

                this.notifySuccess(this.t('sreq.create.success'));

                if (addNew) {
                    this.$refs.form.finalForm.restart(this.initialValues);
                } else {
                    this.submitted = true;
                    this.close();
                }
            } catch (error) {
                // update attachments field for retry call
                const problems = values.problems.map(problem => ({
                    ...problem,
                    attachments: problem.attachments.map(attachment => {
                        const updatedAttachment = this.attachments.find(item => item.id === attachment.id);

                        return updatedAttachment || attachment;
                    }),
                }));
                this.$refs.form.finalForm.change('problems', problems);

                this.notifyError(error.message);
            } finally {
                this.loading = false;
            }
        },

        getCategoryPayload(categoryPath) {
            const getLastPredefinedCategory = categoryPath => {
                return categoryPath.filter(id => id).slice(-1)[0];
            };

            const id = getLastPredefinedCategory(categoryPath ?? []);

            return id && { id };
        },

        getRequestPayload(values) {
            const location = this.getLastPredefinedLocation(values.locationPath) || {};

            const payload = {
                metadata: {
                    alarmCode: values.alarmCode,
                    enterPermissionGranted: values.enterPermissionGranted,
                    notice: values.notice,
                    location: {
                        id: location.key,
                        type: location.type,
                    },

                    customLocation: false,
                    contactPreferences: {
                        preferredChannel: values.contactPreferences.preferredChannel,
                        email: values.contactPreferences.preferredChannel === 'EMAIL' ? values.contactPreferences.email : null,
                        phone: values.contactPreferences.preferredChannel === 'PHONE' ? values.contactPreferences.phone : null,
                    },

                    notificationPreferences: values.notificationPreferences,
                },

                requests: values.problems.map(problem => ({
                    attachments: problem.attachments.map(({ id }) => ({ id })),
                    category: this.getCategoryPayload(problem.categoryPath),
                    description: problem.description,
                    appliance: {
                        id: problem.appliance,
                    },
                })),
            };

            return payload;
        },

        close() {
            this.$refs.page.close();
        },
    },
};
</script>

<style scoped>
.problem-icon-wrapper {
    @apply h-4 w-4 bg-purple-500 flex items-center justify-center mr-2;

    border-radius: 50%;

    & :deep(path) {
        stroke-width: 2px;
    }

    &--red {
        @apply bg-red-500 text-red-900;
    }
}
</style>
