<template>
    <div class="form-field">
        <label class="label mb-0">{{ label }}</label>
        <FinalField
            v-for="option in options"
            :key="option.name"
            :name="option.name"
        >
            <template v-slot="props">
                <div class="mt-4">
                    <CheckboxInput
                        :label="option.label"
                        :modelValue="props.value"
                        @blur="props.events.blur()"
                        @focus="props.events.focus()"
                        @update:modelValue="props.change($event)"
                    />
                </div>
            </template>
        </FinalField>
    </div>
</template>

<script>
import { FinalField } from 'vue-final-form';
import CheckboxInput from '@/components/ui/CheckboxInput';

export default {
    components: {
        FinalField,
        CheckboxInput,
    },

    props: {
        label: {
            type: String,
            required: true,
        },

        options: {
            type: Array,
            required: true,
        },
    },
};
</script>
