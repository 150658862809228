import Axios from 'axios';
import NotifyMixin from '@/mixins/NotifyMixin';

export default {
    mixins: [NotifyMixin],

    data() {
        return {
            attachments: [],

            // overridable
            serviceId: 'ATTS',
        };
    },

    methods: {
        async uploadAttachments() {
            const notUploaded = this.attachments.filter(({ uploaded }) => !uploaded);

            if (notUploaded.length === 0) return;

            notUploaded.forEach(item => {
                item.inProgress = true;
            });

            const uploadData = await this._prepareUploadUrls(notUploaded);

            await Promise.all(
                notUploaded.map((attach, index) => {
                    const { uploadUrl, id } = uploadData[index];
                    return this._uploadFile(uploadUrl, attach.file)
                        .then(() => {
                            attach.id = id;
                            attach.uploaded = true;
                        })
                        .catch(error => {
                            attach.error = error;
                            attach.retry = () => this._retrySingleUpload(attach);
                        });
                })
            );

            notUploaded.forEach(item => {
                item.inProgress = false;
            });

            const success = this.attachments.reduce((acc, cv) => acc && !cv.error, true);

            if (!success) {
                throw new Error(this.t('attachments.upload_failed'));
            }
        },

        async _retrySingleUpload(item) {
            item.inProgress = true;
            try {
                const [uploadData] = await this._prepareUploadUrls([item]);
                await this._uploadFile(uploadData.uploadUrl, item.file);
                item.id = uploadData.id;
                item.uploaded = true;
                item.action = 'download';
            } catch (error) {
                item.error = error;
                item.retry = () => this._retrySingleUpload(item);
                this.notifyError(this.t('attachments.upload_failed'));
            } finally {
                item.inProgress = false;
            }
        },

        _prepareUploadUrls(attachments) {
            return this.$attachmentsDataProvider.create('attachments', {
                data: attachments.map(({ contentType, filename, size }) => ({
                    contentType,
                    filename,
                    size,
                    serviceId: this.serviceId,
                })),
            });
        },

        _uploadFile(uploadUrl, file) {
            return Axios.put(uploadUrl, file, {
                headers: {
                    'content-type': file.type,
                    'x-amz-tagging': 'QUEXT_CONFIRMED=false',
                },
            });
        },
    },
};
